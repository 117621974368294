
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
  onBeforeUpdate,
} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import {
  FolderSurveyLineItemDto,
  RegenerateSurveyPdfRequestDto,
} from "../../core/types/SurveyTypes";

export default defineComponent({
  name: "incline-personal-folder",
  components: {
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const { d } = useI18n();
    const folderLineItems = computed(() => {
      return store.getters.getFolderContents;
    });
    const searchSurveys = ref("");

    const regenerateSurveyRequest = ref({} as RegenerateSurveyPdfRequestDto);
    const regenerateButtons = ref([] as HTMLButtonElement[]);

    onMounted(() => {
      setCurrentPageTitle("Personal JOB Folder");
      setCurrentPageBreadcrumbs("JOB Surveys", ["Personal Folder"]);
    });

    onBeforeUpdate(() => {
      regenerateButtons.value = [];
    });

    store
      .dispatch(Actions.GET_JOB_PERSONAL_FOLDER_CONTENTS, currentUser.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving folder contents.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return folderLineItems.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.jobTitle
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyStatus
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      }
    );

    watch(folderLineItems, () => {
      isDataReady.value = true;
    });

    function regeneratePdf(
      surveyId,
      surveyType,
      respondentId,
      aspNetUserId,
      index
    ) {
      regenerateSurveyRequest.value.respondentId = respondentId;
      regenerateSurveyRequest.value.aspNetUserId = aspNetUserId;
      regenerateSurveyRequest.value.surveyId = surveyId;
      regenerateSurveyRequest.value.surveyType = surveyType;

      regenerateButtons.value[index].disabled = true;
      regenerateButtons.value[index].setAttribute("data-kt-indicator", "on");

      store
        .dispatch(
          Actions.PUT_REGENERATE_SURVEY_PDF,
          regenerateSurveyRequest.value
        )
        .then(() => {
          regenerateButtons.value[index]?.removeAttribute("data-kt-indicator");
          regenerateButtons.value[index].disabled = false;
          Swal.fire({
            text: "Survey has been regenerated!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, great!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch(() => {
          regenerateButtons.value[index]?.removeAttribute("data-kt-indicator");
          regenerateButtons.value[index].disabled = false;
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    return {
      currentUser,
      isDataReady,
      d,
      folderLineItems,
      searchSurveys,
      displayModal,
      filterSurveys,
      regeneratePdf,
      regenerateSurveyRequest,
      regenerateButtons,
    };
  },
});
