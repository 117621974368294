<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title d-flex flex-column mb-5">
            <h3 class="fw-bolder m-0">Personal JOB Survey Folder</h3>
            <h6>[{{ currentUser.firstName }} {{ currentUser.lastName }}]</h6>
          </div>
          <!--end::Card title-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchSurveys"
            name="search"
            placeholder="Search by job title, name or status..."
            data-kt-search-element="input"
          />
        </div>
        <!--end::Search-->
        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin:: Flex Container-->
          <div
            id="incline-flex-table"
            class="d-flex flex-row flex-wrap pb-5 pt-3"
            v-for="(item, index) in filterSurveys"
            :key="index"
            :clientId="'survey_' + index"
            :class="{
              'incline-flex-table-background': index % 2 === 0,
              green: index % 2 !== 0,
            }"
          >
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Job Survey Title</p>
              <p class="incline-flex-table-td">
                <strong>{{ item.jobTitle }}</strong>
              </p>
            </div>
            <div
              class="d-flex flex-column flex-sm-column flex-fill flex-wrap px-5"
            >
              <p class="incline-flex-table-th mb-0">Taken by</p>
              <p class="incline-flex-table-td mb-0">
                {{ item.name }}
              </p>
              <p class="incline-flex-table-td mb-0" style="font-size: 13px">
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
              </p>
            </div>

            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Report</p>
              <p class="incline-flex-table-td mb-1">
                <a
                  :href="'/pdfs/incline-JOBs/' + item.jobResultsPdf"
                  target="_blank"
                >
                  <strong>JOB Results</strong>
                </a>
                <img
                  style="max-width: 20px"
                  src="/media/icons/duotune/files/fil009.svg"
                />
              </p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Survey Graph</p>
              <p>
                <img
                  style="max-width: 225px"
                  :src="'/job-result-grids/' + item.graphFilename"
                  alt="JOB Grid"
                />
              </p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Status</p>
              <p v-show="item.surveyStatus == 'completed'">
                <span class="badge badge-success mx-2 mb-0">Completed</span
                ><br /><span class="mx-2">{{ d(item.completedDate) }}</span>
              </p>
              <p>
                <span
                  v-show="item.surveyStatus == 'pending'"
                  class="badge badge-warning mx-2 mb-0"
                  >Pending</span
                >
              </p>
              <div>
                <button
                  tabindex="3"
                  type="submit"
                  :ref="
                    (el) => {
                      regenerateButtons[index] = el;
                    }
                  "
                  :id="'regeneratePdfBtn-' + index"
                  class="btn btn-light-primary btn-sm"
                  @click="
                    regeneratePdf(
                      item.surveyId,
                      item.surveyType,
                      item.respondentId,
                      item.aspNetUserId,
                      index
                    )
                  "
                >
                  <span class="indicator-label"> Regenerate PDF </span>

                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!--end::Flex Container-->
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
  onBeforeUpdate,
} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import {
  FolderSurveyLineItemDto,
  RegenerateSurveyPdfRequestDto,
} from "../../core/types/SurveyTypes";

export default defineComponent({
  name: "incline-personal-folder",
  components: {
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const { d } = useI18n();
    const folderLineItems = computed(() => {
      return store.getters.getFolderContents;
    });
    const searchSurveys = ref("");

    const regenerateSurveyRequest = ref({} as RegenerateSurveyPdfRequestDto);
    const regenerateButtons = ref([] as HTMLButtonElement[]);

    onMounted(() => {
      setCurrentPageTitle("Personal JOB Folder");
      setCurrentPageBreadcrumbs("JOB Surveys", ["Personal Folder"]);
    });

    onBeforeUpdate(() => {
      regenerateButtons.value = [];
    });

    store
      .dispatch(Actions.GET_JOB_PERSONAL_FOLDER_CONTENTS, currentUser.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving folder contents.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return folderLineItems.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.jobTitle
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyStatus
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      }
    );

    watch(folderLineItems, () => {
      isDataReady.value = true;
    });

    function regeneratePdf(
      surveyId,
      surveyType,
      respondentId,
      aspNetUserId,
      index
    ) {
      regenerateSurveyRequest.value.respondentId = respondentId;
      regenerateSurveyRequest.value.aspNetUserId = aspNetUserId;
      regenerateSurveyRequest.value.surveyId = surveyId;
      regenerateSurveyRequest.value.surveyType = surveyType;

      regenerateButtons.value[index].disabled = true;
      regenerateButtons.value[index].setAttribute("data-kt-indicator", "on");

      store
        .dispatch(
          Actions.PUT_REGENERATE_SURVEY_PDF,
          regenerateSurveyRequest.value
        )
        .then(() => {
          regenerateButtons.value[index]?.removeAttribute("data-kt-indicator");
          regenerateButtons.value[index].disabled = false;
          Swal.fire({
            text: "Survey has been regenerated!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, great!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch(() => {
          regenerateButtons.value[index]?.removeAttribute("data-kt-indicator");
          regenerateButtons.value[index].disabled = false;
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    return {
      currentUser,
      isDataReady,
      d,
      folderLineItems,
      searchSurveys,
      displayModal,
      filterSurveys,
      regeneratePdf,
      regenerateSurveyRequest,
      regenerateButtons,
    };
  },
});
</script>
